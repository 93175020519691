<template>
    <div id="app">
    <div class="jbmb">
        <div class="banner">
          <h1>浙江卫视节目救援</h1>
          <p><span>报告于2020-01-22&nbsp; 09:57由舆情管理系统生成</span></p>
        </div>
        <div class="cont">
            <h2>一、概述</h2>
            <p>本次简报涵盖了 2019-12-22 10:31:17 至 2019-12-23 10:31:17 期间撷取于各大网站及社交平台的 1265 条重点信息。</p>
            <h2>二、监测总览</h2>
            <h3 class="h3">（一）信息声量走势</h3>
            <p>本次的1265条重点信息，发布高峰为2019-12-22 17:00:00 至 2019-12-22 18:00:00 的时段，共282条信息，占总量中的22.29%。</p>
            <div class="box-card xxsmzs h-400">
                <h3 class="mod-title">信息声量走势</h3>
                <div id="voicetrend"></div>
            </div>
            <h3 class="h3">（二）敏感信息走势</h3>
            <p>本次的1265条重点信息，敏感信息共620条，占总量中的49.01%。从走势来看，发布高峰为2019-12-22 18:00:00 至 2019-12-22 19:00:00的时段，共143条，占所有敏感信息的23.06%，高峰时间与整体信息的声量走势一致。</p>
            <div class="flex">
                <div class="box-card w-50 h-400">
                    <h3 class="mod-title">情感声量走势</h3>
                    <div id="emotiontrend"></div>
                </div>
                <div class="box-card w-50 h-400">
                    <h3 class="mod-title">敏感信息占比</h3>
                    <div id="sensitive"></div>
                </div>
            </div>
            <h3 class="h3">（三）信息来源分布</h3>
            <p>本次的1265条重点信息，来自`微博`的共752条，占59.45%。来自`评论`的共174条，占13.75%。来自`网媒`的共149条，占11.78%。来自`自媒体号`的共127条，占10.04%。来自`客户端`的共53条，占4.19%。来自`论坛`的共5条，占0.4%。来自`视频`的共4条，占0.32%。来自`微信`的共1条，占0.08%。其中最大的来源分别为：微博、评论、网媒。</p>
            <div class="flex">
                <div class="box-card w-50 mtly">
                    <h3 class="mod-title">信息来源分布</h3>
                    <div id="mediaSourceMap"></div>
                </div>
                <div class="box-card w-50 mtly">
                    <h3 class="mod-title">微博主要媒体来源</h3>
                    <div class="tab-content mar-t-30">
                        <ul>
                            <li>
                                <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                            </li>
                            <li>
                                <span class="num bg-555">1</span>
                                <a>sunyuemxb</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 1.46%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">11条</span>
                                <span class="redu">1.46%</span>
                            </li>
                            <li>
                                <span class="num bg-689">2</span>
                                <a>DGyjn茜茜</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.6%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">5条</span>
                                <span class="redu">0.66%</span>
                            </li>
                            <li>
                                <span class="num bg-8ea">3</span>
                                <a>Everytime紫爱</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.6%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">5条</span>
                                <span class="redu">0.66%</span>
                            </li>
                            <li>
                                <span class="num">4</span>
                                <a>er姐er姑娘</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.5%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">4条</span>
                                <span class="redu">0.53%</span>
                            </li>
                            <li>
                                <span class="num">5</span>
                                <a>ZTT的周宝宝</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.4%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">3条</span>
                                <span class="redu">0.40%</span>
                            </li>
                            <li>
                                <span class="num">6</span>
                                <a>zoe璐啦啦啦</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.4%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">3条</span>
                                <span class="redu">0.40%</span>
                            </li>
                            <li>
                                <span class="num">7</span>
                                <a>哇亲爱的L</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.4%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">3条</span>
                                <span class="redu">0.40%</span>
                            </li>
                            <li>
                                <span class="num">8</span>
                                <a>槑槑不是四个呆</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.4%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">3条</span>
                                <span class="redu">0.40%</span>
                            </li>
                            <li>
                                <span class="num">9</span>
                                <a>136g的小橘与小柒</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.2%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">2条</span>
                                <span class="redu">0.27%</span>
                            </li>
                            <li>
                                <span class="num">10</span>
                                <a>Catnip_C</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.2%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">2条</span>
                                <span class="redu">0.27%</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="flex">
                <div class="box-card w-50 mtly">
                    <h3 class="mod-title">评论主要媒体来源</h3>
                    <div class="tab-content mar-t-30">
                        <ul>
                            <li>
                                <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                            </li>
                            <li>
                                <span class="num bg-555">1</span>
                                <a>救援抢险网</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 24%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">36条</span>
                                <span class="redu">24%</span>
                            </li>
                            <li>
                                <span class="num bg-689">2</span>
                                <a>冲孔机网</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 17%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">26条</span>
                                <span class="redu">17%</span>
                            </li>
                            <li>
                                <span class="num bg-8ea">3</span>
                                <a>沧州日报新闻网</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 8%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">12条</span>
                                <span class="redu">8%</span>
                            </li>
                            <li>
                                <span class="num">4</span>
                                <a>上海毅兮地坪工程有限公司</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 7%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">11条</span>
                                <span class="redu">7%</span>
                            </li>
                            <li>
                                <span class="num">5</span>
                                <a>呼和浩特崭兔父商贸新闻网</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 3.3%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">5条</span>
                                <span class="redu">3.35%</span>
                            </li>
                            <li>
                                <span class="num">6</span>
                                <a>腾讯网</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 3.3%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">5条</span>
                                <span class="redu">3.35%</span>
                            </li>
                            <li>
                                <span class="num">7</span>
                                <a>www.588k8.com</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 2%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">3条</span>
                                <span class="redu">2%</span>
                            </li>
                            <li>
                                <span class="num">8</span>
                                <a>沛县萌勺敲电子科技新闻网</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 2%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">3条</span>
                                <span class="redu">2%</span>
                            </li>
                            <li>
                                <span class="num">9</span>
                                <a>南京南房建设监理咨询有限公司</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 1.3%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">2条</span>
                                <span class="redu">1.34%</span>
                            </li>
                            <li>
                                <span class="num">10</span>
                                <a>南京生活网</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 1.3%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">2条</span>
                                <span class="redu">1.34%</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="box-card w-50 mtly">
                    <h3 class="mod-title">网媒主要媒体来源</h3>
                    <div class="tab-content mar-t-30">
                        <ul>
                            <li>
                                <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                            </li>
                            <li>
                                <span class="num bg-555">1</span>
                                <a>精选优质网文</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 100%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">1条</span>
                                <span class="redu">100%</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <h3 class="h3">（四）信息地区分布</h3>
            <p>本次的1265条重点信息，主要分布于：其他，北京，广东。敏感信息则主要分布于：其他，北京，广东，与总量的分布一致。</p>
            <div class="flex">
                <div class="box-card w-50 mtly">
                    <h3 class="mod-title">主要发布地区</h3>
                    <div class="hide">
                        <ul class="nav nav-pills">
                            <li class="active"><a>非敏感</a></li>
                            <li><a>敏感</a></li>
                        </ul>
                    </div>
                    <div class="tab-content mar-t-30">
                        <ul>
                            <li>
                                <h5><span class="num_tit">序号</span><span>地区</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                            </li>
                            <li>
                                <span class="num bg-555">1</span>
                                <a>其他</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 63%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">793条</span>
                                <span class="redu">63%</span>
                            </li>
                            <li>
                                <span class="num bg-689">2</span>
                                <a>北京</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 18%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">229条</span>
                                <span class="redu">18%</span>
                            </li>
                            <li>
                                <span class="num bg-8ea">3</span>
                                <a>广东</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 7%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">91条</span>
                                <span class="redu">7%</span>
                            </li>
                            <li>
                                <span class="num">4</span>
                                <a>上海</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 5.5%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">70条</span>
                                <span class="redu">5.5%</span>
                            </li>
                            <li>
                                <span class="num">5</span>
                                <a>湖北</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 4.9%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">62条</span>
                                <span class="redu">4.9%</span>
                            </li>
                            <li>
                                <span class="num">6</span>
                                <a>江苏</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.63%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">8条</span>
                                <span class="redu">0.63%</span>
                            </li>
                            <li>
                                <span class="num">7</span>
                                <a>四川</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.16%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">2条</span>
                                <span class="redu">0.16%</span>
                            </li>
                            <li>
                                <span class="num">8</span>
                                <a>江西</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.16%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">2条</span>
                                <span class="redu">0.16%</span>
                            </li>
                            <li>
                                <span class="num">9</span>
                                <a>湖南</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.16%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">2条</span>
                                <span class="redu">0.16%</span>
                            </li>
                            <li>
                                <span class="num">10</span>
                                <a>内蒙古</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.08%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">1条</span>
                                <span class="redu">0.08%</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="box-card w-50 mtly top_mtly">
                    <h3 class="mod-title"><span class="red">敏感 </span>信息主要发布地区</h3>
                    <div class="tab-content mar-t-30">
                        <ul>
                            <li>
                                <h5><span class="num_tit">序号</span><span>地区</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                            </li>
                            <li>
                                <span class="num bg-555">1</span>
                                <a>其他</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 25%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">326条</span>
                                <span class="redu">25%</span>
                            </li>
                            <li>
                                <span class="num bg-689">2</span>
                                <a>北京</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 10%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">128条</span>
                                <span class="redu">10%</span>
                            </li>
                            <li>
                                <span class="num bg-8ea">3</span>
                                <a>广东</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 5.53%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">70条</span>
                                <span class="redu">5.53%</span>
                            </li>
                            <li>
                                <span class="num">4</span>
                                <a>上海</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 3.95%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">50条</span>
                                <span class="redu">3.95%</span>
                            </li>
                            <li>
                                <span class="num">5</span>
                                <a>湖北</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 3.16%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">40条</span>
                                <span class="redu">3.16%</span>
                            </li>
                            <li>
                                <span class="num">6</span>
                                <a>吉林</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.08%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">1条</span>
                                <span class="redu">0.08%</span>
                            </li>
                            <li>
                                <span class="num">7</span>
                                <a>四川</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.08%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">1条</span>
                                <span class="redu">0.08%</span>
                            </li>
                            <li>
                                <span class="num">8</span>
                                <a>江苏</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.08%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">1条</span>
                                <span class="redu">0.08%</span>
                            </li>
                            <li>
                                <span class="num">9</span>
                                <a>湖南</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.08%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">1条</span>
                                <span class="redu">0.08%</span>
                            </li>
                            <li>
                                <span class="num">10</span>
                                <a>重庆</a>
                                <div class="progressBar">
                                    <dl class="barbox">
                                        <dd class="barline">
                                            <div class="charts" style="width: 0.08%;"></div>
                                        </dd>
                                    </dl>
                                </div>
                                <span class="redu">1条</span>
                                <span class="redu">0.08%</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <h2>三、重点信息摘要</h2>
            <p>本次简报1265条重点信息中最重要的五条如下:</p>
            <table class="mar-t-30">
                <tr>
                    <th width="5%">序号</th>
                    <th>重点信息摘要</th>
                    <th width="8%">发布时间</th>
                    <th width="8%">来源</th>
                    <th width="8%">媒体等级</th>
                    <th width="5%">相似数</th>
                    <th width="5%">属性</th>
                </tr>
                <tr>
                    <td class="t-c">1</td>
                    <td>
                        <h6>标题：高以翔超话 #高以翔# 我真的，只能呼吁大家不要看浙江卫视！不要看浙江卫视！不要看浙江卫视！ 高以翔超话#高以翔百度百科被篡改##抵制浙江卫视跨年晚会#看这个编辑记录大家都明白了，zjws真的改了就以</h6>
                        <p>摘要：高以翔超话 #高以翔# 我真的，只能呼吁大家不要看浙江卫视！不要看浙江卫视！不要看浙江卫视！ 高以翔超话#高以翔百度百科被篡改##抵制浙江卫视跨年晚会#看这个编辑记录大家都明白了，zjws真的改了就以为大家不知道zjws的所作所为吗有时间找人改百度百科，没时间公开追责进度公开道歉哦🙄，数据可以被修改</p>
                        <p>原文链接：https://weibo.com/5031183798/IlYkRCDwi</p>
                    </td>
                    <td class="t-c">2019-12-22 18:32:00</td>
                    <td class="t-c">微博</td>
                    <td class="t-c">普通用户</td>
                    <td class="t-c">1</td>
                    <td class="t-c text-mg">敏感</td>
                </tr>
                <tr>
                    <td class="t-c">2</td>
                    <td>
                        <h6>标题：反正不会再看浙江卫视#浙江卫视# #二十问浙江卫视# #高以翔去世# 高以翔超话#高以翔百度百科被篡改##抵制浙江卫视跨年晚会#看这个编辑记录大家都明白了，zjws真的改了就以为大家不知道zjws的所</h6>
                        <p>摘要：反正不会再看浙江卫视#浙江卫视# #二十问浙江卫视# #高以翔去世# 高以翔超话#高以翔百度百科被篡改##抵制浙江卫视跨年晚会#看这个编辑记录大家都明白了，zjws真的改了就以为大家不知道zjws的所作所为吗有时间找人改百度百科，没时间公开追责进度公开道歉哦🙄，数据可以被修改，但是记忆永远不会被改，</p>
                        <p>原文链接：https://weibo.com/5398272289/IlYpxx3TH</p>
                    </td>
                    <td class="t-c">2019-12-22 18:44:00</td>
                    <td class="t-c">微博</td>
                    <td class="t-c">普通用户</td>
                    <td class="t-c">1</td>
                    <td class="t-c text-mg">敏感</td>
                </tr>
                <tr>
                    <td class="t-c">3</td>
                    <td>
                        <h6>标题：浙江卫视老老实实的承认错误，该道歉道歉，该赔偿赔偿，可能大家还会接受，越这样掩饰，欺骗，把公众当傻瓜糊弄，越让大家反感和抵制</h6>
                        <p>摘要：浙江卫视老老实实的承认错误，该道歉道歉，该赔偿赔偿，可能大家还会接受，越这样掩饰，欺骗，把公众当傻瓜糊弄，越让大家反感和抵制</p>
                        <p>原文链接：https://m.toutiao.com/a6773208121927008775/?id=6773286124606783502</p>
                    </td>
                    <td class="t-c">2019-12-22 23:29:30</td>
                    <td class="t-c">今日头条</td>
                    <td class="t-c">普通媒体</td>
                    <td class="t-c">1</td>
                    <td class="t-c text-mg">敏感</td>
                </tr>
                <tr>
                    <td class="t-c">4</td>
                    <td>
                        <h6>标题：疑浙江卫视工作人员将高以翔百科词条修改，现官方已经删除篡改版</h6>
                        <p>摘要：12月22日，有网友搜索高以翔时，发现他的百科词条被修改，关于人物逝世的介绍中，修改成了浙江卫视单方面说辞的版本，着重强调高以翔是在倒地后1分46秒现场待命的宁波急救中心医护人员赶到并开始实施专业抢救。</p>
                        <p>原文链接：https://baijiahao.baidu.com/s?id=1653592300408219781&wfr=spider&for=pc</p>
                    </td>
                    <td class="t-c">2019-12-22 23:29:30</td>
                    <td class="t-c">百家号</td>
                    <td class="t-c">普通媒体</td>
                    <td class="t-c">1</td>
                    <td class="t-c text-mg">敏感</td>
                </tr>
                <tr>
                    <td class="t-c">5</td>
                    <td>
                        <h6>标题：死zjws可以再不要脸一点！篡改百度百科 内容完全复制于zjws声明 通篇推责于高以翔先生！加粗“专业抢救”字眼 真是呵呵 此地无银三百两！再一次展现卑劣操作 妄想篡改事实 糊弄民众！无良无德至极！#</h6>
                        <p>摘要：死zjws可以再不要脸一点！篡改百度百科 内容完全复制于zjws声明 通篇推责于高以翔先生！加粗“专业抢救”字眼 真是呵呵 此地无银三百两！再一次展现卑劣操作 妄想篡改事实 糊弄民众！无良无德至极！#二十问浙江卫视##高以翔# 高以翔超话#高以翔百度百科被篡改##抵制浙江卫视跨年晚会#看这个编辑记录</p>
                        <p>原文链接：https://weibo.com/5405472935/IlYq8qpSx</p>
                    </td>
                    <td class="t-c">2019-12-22 18:45:00</td>
                    <td class="t-c">微博</td>
                    <td class="t-c">普通用户</td>
                    <td class="t-c">1</td>
                    <td class="t-c text-mg">敏感</td>
                </tr>
            </table>
        </div>
    </div>
</div>
</template>
<script>
    import { globalGetEcharts, globalMediaColor } from "@/utils/helpers";
    export default {
        name: 'brief-exmaple-classic',
        data() {
            return {
                voicetrend: {
                  "keys": ["2019-12-22 10:00:00", "2019-12-22 11:00:00", "2019-12-22 12:00:00", "2019-12-22 13:00:00", "2019-12-22 14:00:00", "2019-12-22 15:00:00", "2019-12-22 16:00:00", "2019-12-22 17:00:00", "2019-12-22 18:00:00", "2019-12-22 19:00:00", "2019-12-22 20:00:00", "2019-12-22 21:00:00", "2019-12-22 22:00:00", "2019-12-22 23:00:00", "2019-12-23 00:00:00", "2019-12-23 01:00:00", "2019-12-23 02:00:00", "2019-12-23 03:00:00", "2019-12-23 04:00:00", "2019-12-23 05:00:00", "2019-12-23 06:00:00", "2019-12-23 07:00:00", "2019-12-23 08:00:00", "2019-12-23 09:00:00", "2019-12-23 10:00:00"],
                  "media": ["全部", "微博", "客户端", "网媒", "自媒体号", "评论", "论坛", "微信", "视频"],
                  "data":  [
                    {
                      "data": [23, 37, 30, 69, 45, 38, 43, 282, 250, 83, 84, 59, 38, 36, 54, 21, 18, 4, 9, 3, 7, 8, 14, 8, 2],
                      "name": "全部",
                      "type": "line"
                    },
                    {
                      "data": [14, 12, 0, 20, 20, 14, 14, 249, 201, 63, 50, 37, 26, 9, 16, 4, 1, 0, 0, 0, 0, 0, 2, 0, 0],
                      "name": "微博",
                      "type": "line"
                    },
                    {
                      "data": [4, 5, 6, 4, 3, 0, 0, 1, 4, 0, 3, 2, 0, 5, 7, 3, 6, 0, 0, 0, 0, 0, 0, 0, 0],
                      "name": "客户端",
                      "type": "line"
                    },
                    {
                      "data": [3, 5, 8, 7, 6, 8, 5, 7, 12, 7, 5, 3, 5, 5, 10, 8, 11, 3, 9, 2, 5, 5, 5, 3, 2],
                      "name": "网媒",
                      "type": "line"
                    },
                    {
                      "data": [2, 9, 12, 2, 11, 2, 16, 2, 12, 3, 6, 5, 3, 13, 14, 2, 0, 1, 0, 1, 0, 3, 3, 5, 0],
                      "name": "自媒体号",
                      "type": "line"
                    },
                    {
                      "data": [0, 5, 4, 36, 4, 14, 8, 21, 20, 10, 20, 12, 4, 4, 6, 0, 0, 0, 0, 0, 2, 0, 4, 0, 0],
                      "name": "评论",
                      "type": "line"
                    },
                    {
                      "data": [0, 1, 0, 0, 1, 0, 0, 2, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                      "name": "论坛",
                      "type": "line"
                    },
                    {
                      "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                      "name": "微信",
                      "type": "line"
                    },
                    {
                      "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                      "name": "视频",
                      "type": "line"
                    }
                  ]
                },
                emotiontrend: {
                  "keys":["2019-12-22 10:00:00", "2019-12-22 11:00:00", "2019-12-22 12:00:00", "2019-12-22 13:00:00", "2019-12-22 14:00:00", "2019-12-22 15:00:00", "2019-12-22 16:00:00", "2019-12-22 17:00:00", "2019-12-22 18:00:00", "2019-12-22 19:00:00", "2019-12-22 20:00:00", "2019-12-22 21:00:00", "2019-12-22 22:00:00", "2019-12-22 23:00:00", "2019-12-23 00:00:00", "2019-12-23 01:00:00", "2019-12-23 02:00:00", "2019-12-23 03:00:00", "2019-12-23 04:00:00", "2019-12-23 05:00:00", "2019-12-23 06:00:00", "2019-12-23 07:00:00", "2019-12-23 08:00:00", "2019-12-23 09:00:00", "2019-12-23 10:00:00"],
                  "positive":[6, 15, 16, 21, 19, 16, 23, 182, 107, 53, 55, 33, 13, 14, 25, 10, 2, 2, 4, 3, 6, 5, 10, 4, 1],
                  "negative":[17, 22, 14, 48, 26, 22, 20, 100, 143, 30, 29, 26, 25, 22, 29, 11, 16, 2, 5, 0, 1, 3, 4, 4, 1]
                },
                sensitive: {"negative":49.01,"positive":50.99},
                sourcedata: [
                  {
                    "name": "微博",
                    "num": 752,
                    "sort": 1,
                    "rate": 59.45
                  },
                  {
                    "name": "评论",
                    "num": 174,
                    "sort": 2,
                    "rate": 13.75
                  },
                  {
                    "name": "网媒",
                    "num": 149,
                    "sort": 3,
                    "rate": 11.78
                  },
                  {
                    "name": "自媒体号",
                    "num": 127,
                    "sort": 4,
                    "rate": 10.04
                  },
                  {
                    "name": "客户端",
                    "num": 53,
                    "sort": 5,
                    "rate": 4.19
                  },
                  {
                    "name": "论坛",
                    "num": 5,
                    "sort": 6,
                    "rate": 0.4
                  },
                  {
                    "name": "视频",
                    "num": 4,
                    "sort": 7,
                    "rate": 0.32
                  },
                  {
                    "name": "微信",
                    "num": 1,
                    "sort": 8,
                    "rate": 0.08
                  }
                ]
            }
        },
        mounted() {
          this.$nextTick(() => {
              this.voic()
              this.emotion()
              this.sen()
              this.source()
          })
        },
        methods: {
            voic(){
                let map = window.document.getElementById('voicetrend')
                var echarts = globalGetEcharts();
                var myChart = echarts.init(map);
                var voicetrend = this.voicetrend;
                let data = voicetrend
                data.data = data.data.map(function (item) {
                    item.itemStyle = {color: globalMediaColor(item.name)}
                    return item
                })
                myChart.setOption({
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        top:"10px",
                        data:data.media,
                        itemWidth:10,
                        itemHeight:10,
                        itemGap:20,
                        icon:"circle"
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            start: 0,
                            end: 100
                        }
                    ],
                    grid: {
                        left: '40px',
                        bottom: '0',
                        right: '60px',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: data.keys
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: data.data
                })
            },
            emotion(){
                let map = window.document.getElementById('emotiontrend')
                var echarts = globalGetEcharts();
                var myChart = echarts.init(map);
                var emotiontrend = this.emotiontrend;
                myChart.setOption({
                    tooltip : {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    legend: {
                        data:['敏感','非敏感'],
                        top:"10px",
                        itemWidth:10,
                        itemHeight:10,
                        itemGap:20,
                        icon:"circle"
                    },
                    grid: {
                        left: '0',
                        bottom: '0',
                        right:"20px",
                        containLabel: true
                    },
                    xAxis : [
                        {
                            type : 'category',
                            boundaryGap : false,
                            data : emotiontrend.keys
                        }
                    ],
                    yAxis : [
                        {
                            type : 'value'
                        }
                    ],
                    dataZoom: [
                        {
                            type: 'inside',
                            start: 0,
                            end: 100
                        }
                    ],
                    color: ['#FC5D73','#555DFE'],
                    series : [
                        {
                            name:'敏感',
                            type:'line',
                            stack: '总量',
                            areaStyle: {normal: {}},
                            data:emotiontrend.negative
                        },
                        {
                            name:'非敏感',
                            type:'line',
                            stack: '总量',
                            areaStyle: {normal: {}},
                            data:emotiontrend.positive
                        }
                    ]
                })
            },
            sen(){
                let map = window.document.getElementById('sensitive')
                var echarts = globalGetEcharts();
                var myChart = echarts.init(map);
                var sensitive = this.sensitive;
                var result = sensitive
                myChart.setOption({
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b}: {c} ({d}%)"
                    },
                    legend: {
                        // orient: 'vertical',
                        // x: 'left',
                        data:['敏感','非敏感'],
                        top:"10px",
                        itemWidth:10,
                        itemHeight:10,
                        itemGap:20,
                        icon:"circle"
                    },
                    color: ['#FC5D73','#555DFE'],
                    series: [
                        {
                            name:'情感分布',
                            type:'pie',
                            center:['50%', '55%'],
                            radius: ['45%', '60%'],
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {
                                    show: true,
                                    textStyle: {
                                        fontSize: '30',
                                        fontWeight: 'bold'
                                    }
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: false
                                }
                            },
                            data: [{name: "敏感", value: result.negative}, {name: "非敏感", value: result.positive}]
                        }
                    ]
                })
            },
            source(){
                var echarts = globalGetEcharts();
                var sourcedata = this.sourcedata;
                var data = sourcedata
                var kMaps = {}
                var total = 0
                var keys = []
                data.map(function (item) {
                    total += item.num
                })
                data = data.map(function (item) {
                    var rate = (item.num / total) * 100
                    rate = rate.toFixed(2).toString()
                    kMaps[item.name] = rate + "% " + item.num + "条"
                    keys.push(item.name)
                    item.itemStyle = {color: globalMediaColor(item.name)}
                    item.value = item.num
                    return item
                })
                var option = {
                    animation: false,
                    tooltip : {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    legend: {
                        orient: 'vertical',
                        left: '0',
                        bottom: "0",
                        data: keys,
                        itemWidth:10,
                        itemHeight:10,
                        itemGap:5,
                        icon:"circle",
                        formatter: function (name) {
                            return name + " " + kMaps[name]
                        }
                    },
                    series : [
                        {
                            name: '来源分布',
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ['50%', '40%'],
                            data: data,
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                },
                                normal : {
                                    label: {
                                        show: false
                                    },
                                    labelLine: {
                                        show: false
                                    }
                                }
                            }
                        }
                    ]
                };
                var map = window.document.getElementById('mediaSourceMap');
                if (!map) return false
                var myChart = echarts.init(map);
                myChart.setOption(option);
            }
        }
    }
</script>
<style scoped>
*{margin:0;padding: 0;}
a{text-decoration: none;cursor: pointer;color: #000;}
li{list-style: none;}
.mar-t-30{margin-top: 30px;}
.t-c{text-align: center;}
#app{background:#E9EEF3;}
.h-400{height: 400px;}
#voicetrend,#emotiontrend,#sensitive{height:370px;width: 100%;}
#mediaSourceMap{height:430px;width: 100%;}
.jbmb{margin:0 auto;background: #fff;padding-bottom: 30px;width:1380px;box-shadow:15px 0 15px -15px rgba(194,194,194,0.4), -15px 0 15px -15px rgba(194,194,194,0.4);}
.banner{text-align: center;line-height: 60px;height: 200px;background-image:url(../../../assets/images/common/banner_bg.png); background-repeat:no-repeat; background-size:100% 100%;-moz-background-size:100% 100%;color: #fff;margin: 0 auto;width: 1380px;position: relative;}
.banner .img{position: absolute;left:100px;top:50px;z-index: 10;height: 60px;}
.banner h1{font-size: 30px;line-height:80px;padding-top:30px;}
.banner span{padding: 0 30px;font-size: 22px;color:rgba(255,255,255,.79);}
.jbmb .cont{padding:20px 50px 0;}
.jbmb h2{font-size: 24px;line-height:60px;margin-top: 20px;}
.jbmb p,.jbmb .h3{font-size: 20px;line-height: 40px;color:rgba(0,0,0,.8);}
.box-card{box-shadow: 0px 3px 15px 0px rgba(194, 194, 194, 0.4);border-radius: 6px;padding: 20px;}
.xxsmzs{width:66%;margin:30px auto;}
.flex{display: flex;}
.w-50{width:49%;margin: 30px 1% 30px 0;}
.nav-pills{margin:5px auto 0;text-align: center;}
.nav-pills li{margin:5px 2px 0;display: inline;}
.nav-pills li a{font-size: 12px;background:#e9e9e9;color:rgba(0,0,0,.47);padding: 6px 18px;border-radius:15px;}
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus{color:#fff;background:#2a67b3;}
.mtly h5{font-size: 14px;line-height: 36px;}
.mtly li{line-height: 36px;font-size: 14px;overflow: hidden;}
.mtly .num_tit{float:left;width: 50px;}
.mtly .num{float: left;width:18px;height:18px;background:#d82008;margin:10px 32px 0 0;display: inline;line-height: 16px;color:#fff;text-align: center;font-size: 12px;border-radius:50%;background: #AFC1DB;}
.mtly .bg-555 {background: #555DFE;}
.mtly .bg-689 {background:#689AFF;}
.mtly .bg-8ea {background:#8EA0FF;}
.mtly .tab-content a{float:left;width:15%;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;}
.mtly .redu{width:60px;text-align: center;float:right;}
.progressBar{float: left;width:50%;margin-top:10px;}
.progressBar .barline{float: left;width: 100%;background: rgb(223, 223, 223);height:14px;overflow: hidden;display: inline;position: relative;border-radius: 8px;}
.progressBar .charts{width: 70%;background-image: linear-gradient(90deg,#409cf3 0%,#5660d2 100%),linear-gradient(#ffffff,#ffffff);background-blend-mode: normal,normal;height:14px;border-radius: 8px;}
.top_mtly .nav-pills > li.active > a,.top_mtly .nav-pills > li.active > a:hover,.top_mtly .nav-pills > li.active > a:focus{color:#fff;background:#f00;}
.top_mtly .charts{background-image: linear-gradient(90deg,#dd897a 0%,#d33e5e 44%,#e11f37 83%,#ee000f 100%),linear-gradient(#96ddfe,#96ddfe);width:90%;}
table,tr,th,td{border:2px solid rgb(223,223,223);border-collapse: collapse;font-size: 14px;line-height: 36px;}
.jbmb th{height: 36px;font-size: 16px;}
.jbmb table p{font-size: 14px;line-height:30px;border-top:2px solid rgb(223,223,223);padding: 0 5px;}
.jbmb h6{line-height: 36px;padding: 0 5px;font-size:16px;}
.jbmb .text-mg{color:red;}
.jbmb .text-fmg,.jbmb .cor-555{color:#555DFE;}
.hide{display:none;}
.mod-title{padding-left: 15px;}
</style>
